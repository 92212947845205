<template>
  <v-text-field
    class="password-field"
    :type="type"
    :value="value"
    v-bind="$attrs"
    @input="onChange"
    @keydown.enter.prevent="onEnter"
  >
    <v-icon slot="append" class="mr-2" @click="toggle(null)">{{ icon }}</v-icon>
  </v-text-field>
</template>
<script>
/**
 * ==================================================================================
 * Password Field
 * ==================================================================================
 **/
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    hideOnStart: {
      type: Boolean,
      default: true,
    },

    onEnter: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      show: false,
      icons: {
        open: mdiEyeOutline,
        close: mdiEyeOffOutline,
      },
    }
  },

  computed: {
    icon() {
      return this.show ? this.icons.open : this.icons.close
    },

    type() {
      return this.show ? 'text' : 'password'
    },
  },

  watch: {
    hideOnStart(value) {
      this.init()
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.toggle(!this.hideOnStart)
    },

    toggle(show = null) {
      this.show = show === null ? !this.show : show
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss" scoped>
.password-field {
  &__toggle {
    cursor: pointer;
  }
}
</style>
