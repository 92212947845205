const USER_TYPES = [
  {
    text: 'SUPER ADMIN',
    value: 'SUPER_ADMIN',
  },
  {
    text: 'ADMIN',
    value: 'ADMIN',
  },
  {
    text: 'STANDARD',
    value: 'STANDARD',
  },
  {
    text: 'READ ONLY',
    value: 'READ_ONLY',
  },
]
export default USER_TYPES
