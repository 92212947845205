<template>
  <div class="create-team">
    <TeamAppbar />
    <div class="ma-4">
      <avatar-form :upload="changeAvatar" />
      <TeamForm :avatarFile="avatarFile" />
    </div>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Team
 * ==================================================================================
 **/

import TeamAppbar from '@/views/Home/Teams/components/TeamAppbar'
import TeamForm from '@/views/Home/Teams/components/TeamForm'
import AvatarForm from '@/components/common/AvatarForm'
import Loading from '@/components/common/Loading'

export default {
  components: {
    TeamAppbar,
    TeamForm,
    AvatarForm,
    Loading,
  },

  data() {
    return {
      loading: false,
      avatarFile: undefined,
    }
  },

  methods: {
    changeAvatar(file) {
      this.avatarFile = file
    },
  },
}
</script>
<style lang="scss">
.create-team {
  //
}
</style>
