<template>
  <v-select
    class="select-status"
    :value="value"
    :items="items"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Status
 * ==================================================================================
 **/
import find from 'lodash/find'

export default {
  props: {
    list: {
      type: Array,
      validator: (value) => value && value.length > 0,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    status: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select status',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Do not allow status to be selected depending on
     * the tier
     */
    hasValidator: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    items() {
      if (!this.hasValidator) {
        return this.list
      }

      const curr = find(this.list, { value: this.status })
      const currStatus = curr ? curr.tier : 1

      return this.list.map((status) => {
        return {
          text: status.text,
          value: status.value,
          disabled:
            this.status === status.value ? true : currStatus > status.tier,
        }
      })
    },
  },

  watch: {},

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
