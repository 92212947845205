<template>
  <div class="create-user">
    <UserAppbar />
    <div class="ma-4">
      <UserForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create User
 * ==================================================================================
 **/

import UserAppbar from '@/views/Home/Users/components/UserAppbar'
import UserForm from '@/views/Home/Users/components/UserForm'

export default {
  components: {
    UserAppbar,
    UserForm,
  },
}
</script>
<style lang="scss">
.create-user {
  //
}
</style>
